import React, { ReactElement } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CenteredContent } from 'src/components/common/Sections';
import CarouselList from 'src/components/HomeContent/TestimonialSection/CarouselList';

interface ITestimonialSection {
  data: {
    title: string;
    headline: string;
    items: ITestimonial[];
  };
}
interface ITestimonial {
  name: string;
  description: string;
  image: string;
}

const TestimonialSection = ({
  data,
}: ITestimonialSection): ReactElement => {
  const { title, headline, items } = data;
  return (
    <CenteredContent title={title} headline={headline}>
      <Row style={{ paddingBottom: '10rem' }}>
        <Col lg="12" style={{ padding: 0 }}>
          <CarouselList testimonials={items} />
        </Col>
      </Row>
    </CenteredContent>
  );
};

export default TestimonialSection;

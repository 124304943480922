import React, { ReactElement } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IPost } from 'src/utils/ts/blog';
import { CenteredContent } from 'src/components/common/Sections';
import Button from 'src/components/common/Button';
import Carousel from 'src/components/HomeContent/BlogSection/Carousel';
import { ButtonWrapper, CarouselWrapper } from './styles';

interface IBlogSection {
  data: {
    title: string;
    headline: string;
    backgroundImage?: string;
  };
  posts: {
    node: IPost;
  }[];
}

const BlogSection = ({ data, posts }: IBlogSection): ReactElement => {
  const { title, headline, backgroundImage } = data;

  return (
    <CenteredContent
      title={title}
      headline={headline}
      backgroundImage={backgroundImage}
    >
      <CarouselWrapper>
        <Col>
          <Carousel posts={posts} />
        </Col>
      </CarouselWrapper>
      <ButtonWrapper>
        <Col>
          <Button type="button" to="/blog">
            View More
          </Button>
        </Col>
      </ButtonWrapper>
    </CenteredContent>
  );
};

export default BlogSection;

import React, { ReactElement } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CenteredContent } from 'src/components/common/Sections';
import Button from 'src/components/common/Button';
import Card from './Card';
import { ButtonWrapper } from './styles';

interface IServiceSection {
  data: {
    title: string;
    headline: string;
    items: IService[];
  };
}
interface IService {
  title: string;
  description: string;
  image: string;
  link: string;
}

const ServiceSection = ({ data }: IServiceSection): ReactElement => {
  const { title, headline, items } = data;
  return (
    <CenteredContent title={title} headline={headline}>
      <Row>
        {items.map(
          (
            { title, description, image, link }: IService,
            i: number,
          ) => (
            <Card
              key={i}
              title={title}
              description={description}
              image={image}
              link={link}
            />
          ),
        )}
      </Row>
      <ButtonWrapper>
        <Col>
          <Button type="button" to="/product">
            Get Quote
          </Button>
        </Col>
      </ButtonWrapper>
    </CenteredContent>
  );
};

export default ServiceSection;

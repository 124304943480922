import React, { ReactElement } from 'react';
import { ColumnContent } from 'src/components/common/Sections';

interface IInfoSection {
  data: {
    title: string;
    headline: string;
    image: string;
    backgroundImage: string;
    type: string;
    items: IItem[];
  }[];
}
interface IItem {
  title: string;
  description: string;
  icon?: string;
}

const InfoSection = ({ data }: IInfoSection): ReactElement => {
  return (
    <>
      <ColumnContent data={data[0]}></ColumnContent>
      <ColumnContent data={data[1]}></ColumnContent>
      <ColumnContent data={data[2]}></ColumnContent>
    </>
  );
};

export default InfoSection;

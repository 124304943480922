import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { HTMLAttributes } from 'react';

const animationTiming = 6;
const changeTiming = 3;
const textColor = 'transparent';

interface ItemProps {
  i: number;
  count: number;
}

const getSteps = (items: number) => 100 / items;
const getDelay = (items: number) => (items * animationTiming) / items;

const carouselAnimation = (props: ItemProps) => keyframes`
  0%  {
    transform: translateX(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
    margin-top: 0rem;
    color: ${textColor};
  }
  ${changeTiming}%, ${getSteps(props.count)}% {
    transform: translateX(100%) scale(0.7);
    opacity: 0.4;
    visibility: 0.4;
    margin-top: 0rem;
    color: ${textColor};
  }
  ${getSteps(props.count) + changeTiming}%, ${
  getSteps(props.count) * 2
}% {
    transform: translateX(0) scale(1);
    opacity: 1;
    margin-top: 3rem;
    visibility: visible;
    color: #5a5a5a;
  }
  ${getSteps(props.count) * 2 + changeTiming}%, ${
  getSteps(props.count) * 3
}% {
    transform: translateX(-100%) scale(0.7);
    opacity: 0.4;
    visibility: 0.4;
    margin-top: 0rem;
    color: ${textColor};
  }
  ${getSteps(props.count) * 3 + changeTiming}% {
    transform: translateX(-100%) scale(0.5);
    opacity: 0;
    margin-top: 0rem;
    visibility: 0.4;
    color: ${textColor};
  }
  100% {
    transform: translateX(-100%) scale(0.5);
    opacity: 0;
    margin-top: 0rem;
    visibility: hidden;
    color: ${textColor};
  }
`;

export const Wrapper = styled.div`
  margin: 8rem 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Carousel = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

interface ExtendedItem extends HTMLAttributes<HTMLDivElement> {
  i: number;
  count: number;
}

export const Item = styled.div<ExtendedItem>`
  display: flex;
  align-items: center;
  position: absolute;
  opacity: 0;
  padding-bottom: 3rem;
  // filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  animation: ${(props) => carouselAnimation(props)}
    ${(props) => props.count * animationTiming}s linear infinite;
  animation-delay: calc(
    ${(props) => getDelay(props.count)}s * ${(props) => props.i - 2}
  );
`;

export const ItemBody = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemHead = styled.img``;

export const ItemDescription = styled.p`
  font-size: 20px;
  margin: 5rem 0 6rem 0;
  text-align: center;
  width: 350%;
  @media (max-width: 768px) {
    width: 250%;
  }
`;

import styled from '@emotion/styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Wrapper = styled(Row)`
  background: url(${(props) => props.background}) no-repeat center top;
  width: 100%;
  object-fit: fill;
  margin-bottom: 5rem;
`;

export const Content = styled(Row)`
  margin-left: 5rem;
  margin-right: 5rem;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 120%;
  @media (max-width: 992px) {
    width: 110%;
  }
`;

export const ColumnWrap = styled(Col)`
  margin-top: 2rem;
  padding: 4rem;
  align-items: center;
  @media (max-width: 992px) {
    padding: 1rem 0;
    margin: auto;
  }
`;

export const SectionTitle = styled.h6`
  font-weight: ${({ theme }) =>
    theme.sections.headlines.xsmall.fontWeight};
  font-size: ${({ theme }) =>
    theme.sections.headlines.xsmall.fontSize};
  color: ${({ theme }) => theme.sections.headlines.xsmall.color};
  margin-bottom: 2rem;
  margin-top: 4rem;
`;

export const Headline = styled.h2`
  font-weight: ${({ theme }) =>
    theme.sections.headlines.large.fontWeight};
  font-size: ${({ theme }) =>
    theme.sections.headlines.large.fontSize};
  color: ${({ theme }) => theme.sections.headlines.large.color};
  margin-bottom: 2rem;
`;

export const RowTitle = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) =>
    theme.sections.headlines.xsmall.fontSize};
  color: ${({ theme }) => theme.sections.headlines.large.color};
`;

export const RowDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.sections.headlines.large.color};
`;

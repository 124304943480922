import React, { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import InfoCard from './InfoCard';
import { Wrapper, Content, Image, ColumnWrap } from './styles';
interface IColumnContent {
  data: {
    title: string;
    headline: string;
    image: string;
    backgroundImage: string;
    type: string;
    items: { title: string; description: string }[];
  };
}

const ColumnContent = ({ data }: IColumnContent): ReactElement => {
  return (
    <Wrapper background={data.backgroundImage} noGutters>
      <Container>
        <Content>
          {data.type === 'right' ? (
            <>
              <ColumnWrap sm="12" lg="6">
                <Image src={data.image} />
              </ColumnWrap>
              <ColumnWrap sm="12" lg="6">
                <InfoCard
                  title={data.title}
                  headline={data.headline}
                  items={data.items}
                />
              </ColumnWrap>
            </>
          ) : (
            <>
              <ColumnWrap sm="12" lg="6">
                <InfoCard
                  title={data.title}
                  headline={data.headline}
                  items={data.items}
                />
              </ColumnWrap>
              <ColumnWrap sm="12" lg="6">
                <Image src={data.image} />
              </ColumnWrap>
            </>
          )}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ColumnContent;

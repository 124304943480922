import styled from '@emotion/styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const ButtonWrapper = styled(Row)`
  padding: 5rem;
`;

export const CardWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    text-decoration: none;
  }
`;

export const Image = styled.img`
  margin-left: -1.4rem;
`;

export const Title = styled.h6`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 22px;
  font-weight: bold;
`;

export const Description = styled.p`
  :hover {
    text-decoration: none;
  }
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 26px;
`;

import React, { ReactElement } from 'react';
import { Tags } from '@tryghost/helpers-gatsby';
import { IPost } from 'src/utils/ts/blog';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import {
  LinkWrapper,
  ItemCard,
  Body,
  TagsWrapper,
  Details,
  Title,
} from './styles';

interface IBlogCard {
  post: IPost;
}

const BlogCard = ({ post }: IBlogCard): ReactElement => {
  const url = `/blog/${post.slug}/`;

  return (
    <Col lg="4">
      <LinkWrapper to={url}>
        <ItemCard>
          {post.feature_image && (
            <Card.Img variant="top" src={post.feature_image} />
          )}
          <Body>
            {post.tags && (
              <TagsWrapper>
                <Tags
                  post={post}
                  visibility="public"
                  autolink={false}
                  separator=" | "
                />
              </TagsWrapper>
            )}
            <Details>
              on {post.published_at_pretty} / by{' '}
              {post.primary_author.name}
            </Details>
            <Title>{post.title}</Title>
          </Body>
        </ItemCard>
      </LinkWrapper>
    </Col>
  );
};

export default BlogCard;

import styled from '@emotion/styled';
import Row from 'react-bootstrap/Row';

export const ButtonWrapper = styled(Row)`
  padding: 5rem;
`;

export const CarouselWrapper = styled(Row)`
  margin-bottom: 6rem;
`;

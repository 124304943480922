import React, { ReactElement, ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Hero, Content, Image, Title, Paragraph } from './styles';

interface IHeroSection {
  data: {
    title: string;
    description: string;
    image: string;
  };
  children?: ReactNode;
}

const HeroSection = ({
  data,
  children,
}: IHeroSection): ReactElement => (
  <Row noGutters>
    <Hero>
      <Content md="12" lg="5">
        <Title>{data.title}</Title>
        <Paragraph>{data.description}</Paragraph>
        {children}
      </Content>
      <Col md="12" lg="7">
        <Image src={data.image} />
      </Col>
    </Hero>
  </Row>
);

export default HeroSection;

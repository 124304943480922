import React, { ReactElement } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { IPost } from 'src/utils/ts/blog';
import BlogCard from 'src/components/HomeContent/BlogSection/BlogCard';
import { Content, CarouselComponent } from './styles';

interface ICarousel {
  posts: IPostArray[];
}

interface IPostArray {
  node: IPost;
}

const CarouselWrapper = ({ posts }: ICarousel): ReactElement => {
  const chunkedPosts = new Array(Math.ceil(posts.length / 3))
    .fill((item: any) => item)
    .map(() => posts.splice(0, 3));

  return (
    <CarouselComponent as="div" controls={false}>
      {chunkedPosts.map((chunk: IPostArray[], i: number) => {
        return (
          <Carousel.Item key={i}>
            <Content>
              {chunk.map(({ node }: { node: IPost }) => (
                <BlogCard key={node.id} post={node} />
              ))}
            </Content>
          </Carousel.Item>
        );
      })}
    </CarouselComponent>
  );
};

export default CarouselWrapper;

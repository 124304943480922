import styled from '@emotion/styled';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export const Wrapper = styled(Row)`
  background: url(${(props) => props.background}) no-repeat center top;
  width: 100%;
  object-fit: fill;
  margin-bottom: 5rem;
  padding-top: 4rem;
`;

export const Head = styled(Col)`
  text-align: center;
`;

export const Title = styled.h4`
  font-size: ${({ theme }) =>
    theme.sections.headlines.small.fontSize};
  color: ${({ theme }) => theme.sections.headlines.small.color};
`;

export const Headline = styled.h4`
  font-size: ${({ theme }) =>
    theme.sections.headlines.medium.fontSize};
  color: ${({ theme }) => theme.sections.headlines.medium.color};
  font-weight: ${({ theme }) =>
    theme.sections.headlines.medium.fontWeight};
  padding: 1rem 10rem;
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

export const HorizontalLine = styled.div`
  width: 75px;
  height: 8px;
  border-radius: 7px;
  margin: auto;
  background-image: linear-gradient(to bottom, #f24343, #e02020);
`;

export const Body = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 3rem;
`;

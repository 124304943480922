import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.primary};
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const ItemCard = styled(Card)`
  border: none;
`;

export const Body = styled(Card.Body)`
  padding: 1.25rem 0;
`;

export const TagsWrapper = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.small}
}
`;

export const Details = styled.p`
  color: #9aa0a6;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

export const Title = styled(Card.Title)`
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
`;

import React, { ReactElement, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {
  Wrapper,
  Head,
  Title,
  Headline,
  HorizontalLine,
  Body,
} from './styles';

interface ICenteredContent {
  title: string;
  headline: string;
  children: ReactNode;
  backgroundImage?: string;
}

const CenteredContent = ({
  title,
  headline,
  children,
  backgroundImage = '',
}: ICenteredContent): ReactElement => {
  return (
    <Wrapper background={backgroundImage} noGutters>
      <Container>
        <Row noGutters>
          <Head lg="12">
            <Title>{title}</Title>
            <HorizontalLine />
            <Headline>{headline}</Headline>
          </Head>
          <Body lg="12">{children}</Body>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default CenteredContent;

import React, { ReactElement, ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  SectionTitle,
  Headline,
  RowTitle,
  RowDescription,
} from './styles';

const Item = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <Row>
    <Col lg="12">
      <RowTitle>{title}</RowTitle>
      <RowDescription>{description}</RowDescription>
    </Col>
  </Row>
);

interface IInfoCard {
  title: string;
  headline: string;
  items: { title: string; description: string }[];
}

const InfoCard = ({
  title,
  headline,
  items,
}: IInfoCard): ReactElement => {
  return (
    <Row>
      <Col lg="12">
        <SectionTitle>{title}</SectionTitle>
        <Headline>{headline}</Headline>
      </Col>
      <Col lg="12">
        {items.map(({ title, description }, i) => (
          <Item title={title} description={description} key={i} />
        ))}
      </Col>
    </Row>
  );
};

export default InfoCard;

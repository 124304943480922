import React, { ReactElement, useState } from 'react';
import {
  Wrapper,
  Carousel,
  Item,
  ItemBody,
  ItemHead,
  ItemDescription,
} from './styles';

interface ICarouselList {
  testimonials: {
    name: string;
    description: string;
    image: string;
  }[];
}

const CarouselList = ({
  testimonials,
}: ICarouselList): ReactElement => {
  return (
    <Wrapper>
      <Carousel>
        {testimonials.map((item, i) => (
          <Item key={i} i={i} count={testimonials.length}>
            <ItemBody>
              <ItemHead src={item.image} width="178px" />
              <ItemDescription>{item.description}</ItemDescription>
            </ItemBody>
          </Item>
        ))}
      </Carousel>
    </Wrapper>
  );
};

export default CarouselList;

import styled from '@emotion/styled';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

export const Hero = styled(Container)`
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 0;
  background-color: ${({ theme }) => theme.hero.backgroundColor};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
`;

export const Content = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: ${({ theme }) => theme.hero.title.fontSize};
  color: ${({ theme }) => theme.hero.title.color};
  padding-bottom: 1rem;
`;

export const Paragraph = styled.p`
  padding: 1rem 0;
  font-size: ${({ theme }) => theme.hero.paragraph.fontSize};
  color: ${({ theme }) => theme.hero.paragraph.color};
`;

export const Image = styled.img`
  object-fit: contain;
  width: 110%;
`;

import React, { ReactElement } from 'react';
import { CardWrapper, Image, Title, Description } from './styles';
import { Link } from 'gatsby';
interface IService {
  title: string;
  description: string;
  image: string;
  link: string;
}

const Card = ({
  title,
  description,
  image,
  link,
}: IService): ReactElement => {
  return (
    <CardWrapper sm="6" lg="3">
      <Link to={link}>
        <Image src={image} />
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Link>
    </CardWrapper>
  );
};

export default Card;

import styled from '@emotion/styled';
import Carousel from 'react-bootstrap/Carousel';

export const CarouselComponent = styled(Carousel)`
  ol {
    bottom: -6rem;
    li {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #efefef;
      opacity: 1;
    }
    li.active {
      background-image: linear-gradient(to bottom, #f24343, #e02020);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  height: 360px;
`;
